<template>
  <section class="container blog">
    <div class="row m0 blog-content">
      <div class="col-md-8 col-tablet">
        <div class="row m0 shadow-hover-elemet">
          <p class="bg-cl-white col-xs-12 pl20 py5 m0 cl-white bg-cl-alternative h4 uppercase">
            {{ $t('Porady') }}
          </p>
          <div class="row m0 bg-cl-white p0 w-100 top-xs">
            <div v-for="post in latestPosts" class="col-xs-12 col-md-4" :key="post.id">
              <router-link :to="localizedRoute('/porady') + '/' + post.slug">
                <img v-lazy="post.featuredImage.node.medium" :alt="post.title" class="blog-image">
                <div class="pl15 mb15">
                  <p class="weight-500">
                    {{ post.title }}
                  </p>
                  <span class="fs-medium-small cl-dark-blue">
                    {{ $t('Read more') }} >
                  </span>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-xs-12 bg-cl-white end-xs py20 learn-more brdr-top-3 brdr-cl-white-smoke">
            <router-link :to="localizedRoute('/porady')" class="cl-dark-blue fs-medium-small">
              {{ $t('Learn More') }} >
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { Wordpress } from 'src/modules/vsf-wordpress'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  beforeCreate () {
    registerModule(Wordpress)
  },
  computed: {
    latestPosts () {
      return this.$store.getters['wordpress/posts'].slice(0, 3)
    }
  },
  methods: {
  },
  created () {
    return this.$store.dispatch('wordpress/loadPosts', { first: 3 })
  }
}
</script>

<style lang="scss" scoped>
.shadow-hover-elemet {
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}
.blog {
  margin-top: 60px;
  @media (max-width: 767px) {
    margin-top: 30px;
    padding: 0;
  }
  .blog-content {
    padding: 8px 0;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  .blog-image {
    width: 100%;
    height: auto;
    margin-top: 10px;
    max-height: 280px;
    display: flex;
    @media (max-width: 767px) {
      display: flex;
      margin: 10px auto 0 auto;
      width: 100%;
      max-width: 300px;
      max-height: auto;
      height: auto;
    }
  }
}
</style>
